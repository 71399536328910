import keyFrames from './keyFrames'
import { uuid } from 'vue-uuid'
class PlayAudio {
  constructor(vm, data, time) {
    this.vm = vm
    this.data = { ...data }
    this.time = 0
    this.canplay = true
    this.audio = new Audio()
    this.audio.src = data.file.url
    this.audio.currentTime = Number(data.startTime) / 1000
    if (this.data.canWait == false) {
      this.audio.autoplay = true
    }
    if (this.data.loop != undefined) {
      this.audio.loop = this.data.loop
    }
    if (time) {
      var d = time - this.data.timeline
      if (d > 0) {
        this.time = d
        this.audio.currentTime = Number(data.startTime + d) / 1000
        this.data.timeline = time
        this.data.duration = this.data.duration - d
      }
    }
    this.audio.addEventListener('audioprocess', (e) => { console.log('audio audioprocess', e) })
    this.audio.addEventListener('complete', (e) => { console.log('audio complete', e) })
    this.audio.addEventListener('durationchange', (e) => { console.log('audio durationchange', e) })
    this.audio.addEventListener('emptied', (e) => { console.log('audio emptied', e) })
    this.audio.addEventListener('ended', (e) => { console.log('audio ended', e) })
    this.audio.addEventListener('loadeddata', (e) => { console.log('audio loadeddata', e) })
    this.audio.addEventListener('loadedmetadata', (e) => { console.log('audio loadedmetadata', e) })
    this.audio.addEventListener('pause', (e) => { console.log('audio pause', e) })
    this.audio.addEventListener('play', (e) => { console.log('audio play', e) })
    this.audio.addEventListener('ratechange', (e) => { console.log('audio ratechange', e) })
    this.audio.addEventListener('seeked', (e) => { console.log('audio seeked', e) })
    this.audio.addEventListener('seeking', (e) => { console.log('audio seeking', e) })
    this.audio.addEventListener('stalled', (e) => { console.log('audio stalled', e) })
    this.audio.addEventListener('suspend', (e) => { console.log('audio suspend', e) })
    this.audio.addEventListener('timeupdate', (e) => { console.log('audio timeupdate', e) })
    this.audio.addEventListener('volumechange', (e) => { console.log('audio volumechange', e) })
    this.audio.addEventListener('canplay', (e) => {
      console.log('audio canplay', e)
      //  this.canplay = true
      //  if (this.waitCanPlay) {
      //    this.waitCanPlay()
      //  }
    })
    this.audio.addEventListener('waiting', (e) => {
      console.log('audio waiting', e)
      if (this.data.waitLoad != false) {
        this.waiting = this.vm.addLoading('self', () => {
          this.waiting = null
        })
      }
    })
    this.audio.addEventListener('canplaythrough', (e) => {
      console.log('audio canplaythrough', e)
      if (this.waiting) {
        this.waiting()
      }
    })
    this.audio.addEventListener('playing', (e) => {
      console.log('audio playing', e)
      if (this.waiting) {
        this.waiting()
      }
    })
    this.keyFrames = new keyFrames(data.keyFrames, PlayAudio.DefaultPlayAudioFrame, data.duration, this.trFunc('frameIn'), this.trFunc('frameEnd'), null)
    return this
  }
  static get DefaultPlayAudioFrame() {
    return {
      time: 0,
      volume: 1,
    }
  }
  trFunc(name) {
    return (...arg) => {
      return this[name](...arg)
    }
  }
  frameIn(nowf, nextf) {
    console.log('PlayAudio frameIn', nowf)
    //  this.audio.volume = nowf.volume
    this.startTween(nowf, nextf)
  }
  frameEnd(nowf, nextf) {
    this.stopTween()
  }
  startTween(nowf, nextf) {
    this.time = nowf.time
    this.tweenTime = new Date()
    this.tweenHandle = setInterval(this.trFunc('tween'), 100)
  }
  tween() {
    var f = this.keyFrames.getFrame(this.time + (new Date().getTime() - this.tweenTime.getTime()))
    this.audio.volume = f.volume
  }
  stopTween() {
    clearInterval(this.tweenHandle)
  }
  play() {
    this.keyFrames.play(this.time)
    if (this.data.canWait == false) {
      return
    }
    this.audio.play()
    if (!this.canplay) {
      this.waitCanPlay = this.vm.addLoading('self', () => {
        this.audio.play()
      })
    }
    this.endP = setTimeout(() => { this.end() }, this.data.duration)
  }
  pause() {
    var t = this.keyFrames.stop()
    if (t != null) {
      this.time = t
    }
    this.stopTween()
    if (this.data.canWait == false) {
      return
    }
    clearTimeout(this.endP)
    this.endP = null
    this.audio.pause()
  }
  continue() {
    this.keyFrames.play(this.time)
    if (this.data.canWait == false) {
      return
    }
    var t = this.vm.nowTime
    var time = this.data.duration - (t - this.data.timeline)
    this.audio.play()
    this.endP = setTimeout(() => { this.end() }, time)
  }
  stop() {
    this.end()
  }
  end() {
    clearTimeout(this.endP)
    this.audio.pause()
    this.destroy()
  }
  destroy() {
    console.log('destroy Audio')
    if (this.loading_audio) {
      this.loading_audio()
    }
    this.keyFrames.stop()
    this.stopTween()
    this.audio.src = ''
    this.audio.remove()
    this.vm.removeRunnningAct(this)
  }
}
class Subtitle {
  constructor(vm, data, time) {
    this.vm = vm
    this.data = { ...data }
    //this.stitle = { text: data.text, key: data.guid }
    //if (time) {
    //  var d = time - this.data.timeline
    //  if (d > 0) {
    //    this.data.timeline = time
    //    this.data.duration = this.data.duration - d
    //  }
    //}
    if (time) {
      this.time = time - data.timeline
    } else {
      this.time = 0
    }
    //this.vm.subtitles.push(this.stitle)
    var strings = data.text.split('\n')
    var length = 0
    for (var i in strings) {
      length += strings[i].length
    }
    var frames = []
    var lastTime = 0
    for (var i in strings) {
      frames.push({
        time: Number(lastTime.toFixed(0)),
        text: strings[i],
        key: uuid.v4()
      })
      lastTime += (data.duration / length) * strings[i].length
    }
    this.frames = frames
    this.keyFrames = new keyFrames(frames, PlayAudio.DefaultSubtitleFrame, data.duration, this.trFunc('frameIn'), this.trFunc('frameEnd'), null)
    this.subtitles = []
    //      this.el = document.createElement('div')
    //      this.el.innerHTML = `
    //${data.text}
    //`
    return this
  }
  static get DefaultSubtitleFrame() {
    return {
      time: 0,
      Text: '',
      key: uuid.v4()
    }
  }
  trFunc(name) {
    return (...arg) => {
      return this[name](...arg)
    }
  }
  frameIn(nowf, nextf) {
    this.vm.subtitles.push(nowf)
    this.subtitles.push(nowf)
    //this.stitle = nowf
  }
  frameEnd(nowf, nextf) {
    setTimeout(() => {
      if (this.subtitles.indexOf(nowf) != -1) {
        this.vm.subtitles.splice(this.vm.subtitles.indexOf(nowf), 1)
        this.subtitles.splice(this.subtitles.indexOf(nowf), 1)
      }
    }, (nextf.time - nowf.time) * 0.8)
  }
  clearSubtitles() {
    for (var i in this.subtitles) {
      if (this.vm.subtitles.indexOf(this.subtitles[i]) != -1) {
        this.vm.subtitles.splice(this.vm.subtitles.indexOf(this.subtitles[i]), 1)
      }
    }
    this.subtitles = []
  }
  play() {
    this.clearSubtitles()
    this.keyFrames.play(this.time)
    //setTimeout(() => {
    //  this.stitle.show = true
    //})
    //document.body.appendChild(this.el)
    this.endP = setTimeout(() => { this.end() }, this.data.duration)
  }
  pause() {
    var t = this.keyFrames.stop()
    if (t != null) {
      this.time = t
    }
    clearTimeout(this.endP)
    this.endP = null
  }
  continue() {
    this.clearSubtitles()
    this.keyFrames.play(this.time)
    //this.vm.subtitles.push(this.stitle)
    //var t = this.vm.nowTime
    //var time = this.data.duration - (t - this.data.timeline)
    this.endP = setTimeout(() => { this.end() }, time)
  }
  stop() {
    //setTimeout(() => {
    //  this.stitle.show = false
    //})
    //document.body.removeChild(this.el)
    this.keyFrames.stop()
    this.end()
  }
  end() {
    //setTimeout(() => {
    //  this.stitle.show = false
    //})
    //document.body.removeChild(this.el)
    clearTimeout(this.endP)
    this.destroy()
  }
  destroy() {
    console.log('destroy subtitle')
    this.clearSubtitles()
    this.keyFrames.stop()
    //this.vm.subtitles.splice(this.vm.subtitles.indexOf(this.stitle), 1)
    this.vm.removeRunnningAct(this)
  }
}
class ViewImage {
  constructor(vm, data, time) {
    this.vm = vm
    this.data = { ...data }
    this.keyFrames = new keyFrames(data.keyFrames, ViewImage.DefaultViewImageFrame, data.duration, this.trFunc('frameIn'), this.trFunc('frameEnd'), null)
    this.time = time ? time - data.timeline : 0
    this.imgData = this.ftoImgdata(this.keyFrames.getFrame(this.time))
    this.vm.images.push(this.imgData)
    //if (time) {
    //  var d = time - this.data.timeline
    //  if (d > 0) {
    //    this.data.timeline = time
    //    this.data.duration = this.data.duration - d
    //  }
    //}
    var img = new Image()
    img.src = this.imgData.imgUrl
    img.onload = () => {
      this.vm.$forceUpdate()
    }
    return this
  }
  static get DefaultViewImageFrame() {
    var w = window.screen.width
    var h = window.screen.height
    var s = w > h ? h : w
    s = s * 0.8
    return {
      time: 0,
      rect: {
        width: (s / w) * 100,
        height: (s / h) * 100,
        x: 0,
        y: 0,
      },
      rotate: {
        x: 0, y: 0, z: 0,
      },
      bgMode: {
        size: 'contain',
        position: 'center',
        repeat: 'no-repeat',
      },
      opacity: 1,
      scale: 1,
    }
  }
  ftoImgdata(f) {
    console.log('ftoImgdata', f)
    if (!f) {
      f = {}
    }
    var r = {
      imgUrl: this.data.file.url,
      key: this.data.guid,
      rect: f.rect || ViewImage.DefaultViewImageFrame.rect,
      rotate: f.rotate,
      opacity: f.opacity,
      scale: f.scale,
      bgMode: f.bgMode,
      blendMode: this.data.blendMode,
    }
    return r
  }
  trFunc(name) {
    return (...arg) => {
      return this[name](...arg)
    }
  }
  frameIn(nowf, nextf) {
    console.log('img frameIn')
    //this.imgData = this.ftoImgdata(nowf)
    this.vm.$forceUpdate()
    setTimeout(() => {
      this.imgData.transition = `all ${(nextf.time - nowf.time) / 1000}s linear`
      this.imgData.rect = nextf.rect
      this.imgData.opacity = nextf.opacity
      this.imgData.rotate = nextf.rotate
      this.vm.$forceUpdate()
    })
  }
  frameEnd(nowf, nextf) {
    this.imgData.transition = null
    this.vm.$forceUpdate()
  }
  play() {
    this.endP = setTimeout(() => { this.end() }, this.data.duration - this.time)
    this.keyFrames.play(this.time, () => { console.log('kf ended') })
  }
  pause() {
    var t = this.keyFrames.stop()
    this.time = (this.vm.nowTime || 0) - this.data.timeline
    console.log(`keyFrames(${t})-nowTimeD(${this.time})=${t - this.time}`)
    this.vm.images.splice(this.vm.images.indexOf(this.imgData), 1)
    this.imgData = this.ftoImgdata(this.keyFrames.getFrame(this.time))
    this.vm.images.push(this.imgData)
    clearTimeout(this.endP)
    this.endP = null
  }
  continue() {
    var t = this.vm.nowTime
    this.time = t - this.data.timeline
    this.endP = setTimeout(() => { this.end() }, this.data.duration - this.time)
    this.keyFrames.play(this.time, () => { console.log('kf ended') })
  }
  stop() {
    this.keyFrames.stop()
    this.end()
  }
  end() {
    clearTimeout(this.endP)
    this.destroy()
  }
  destroy() {
    if (this.keyFrames) {
      this.keyFrames.stop()
    }
    console.log('destroy ViewImage')
    this.vm.images.splice(this.vm.images.indexOf(this.imgData), 1)
    this.vm.removeRunnningAct(this)
  }
}
class ViewText {
  constructor(vm, data, time) {
    this.vm = vm
    this.data = { ...data }
    this.keyFrames = new keyFrames(data.keyFrames, ViewText.DefaultViewTextFrame, data.duration, this.trFunc('frameIn'), this.trFunc('frameEnd'), null)
    this.time = time ? time - data.timeline : 0
    this.textData = this.ftoTextdata(this.keyFrames.getFrame(this.time))
    this.vm.texts.push(this.textData)
    //if (time) {
    //  var d = time - this.data.timeline
    //  if (d > 0) {
    //    this.data.timeline = time
    //    this.data.duration = this.data.duration - d
    //  }
    //}
    return this
  }
  static get DefaultViewTextFrame() {
    var w = window.screen.width
    var h = window.screen.height
    var s = w > h ? h : w
    //s = s * 0.8
    return {
      time: 0,
      rect: {
        width: (s / w) * 50,
        height: (s / h) * 10,
        x: 0,
        y: 0,
      },
      rotate: {
        x: 0, y: 0, z: 0,
      },
      opacity: 1,
      scale: 1,
      testShadows: [],
      fontSize: 16,
      lineHeight: 1.5,
      bgColor: '#0000',
      fontColor: '#ffff',
      border: {
        color: '#000',
        width: 0,
        style: 'None'
      }
    }
  }
  ftoTextdata(f) {
    console.log('ftoTextdata', f)
    if (!f) {
      f = {}
    }
    var r = {
      key: this.data.guid,
      text: this.data.text,
      textAlign: this.data.textAlign,
      edge: this.data.edge,
      rect: f.rect || ViewText.DefaultViewTextFrame.rect,
      rotate: f.rotate,
      opacity: f.opacity,
      scale: f.scale,
      fontSize: f.fontSize,
      lineHeight: f.lineHeight,
      fontColor: f.fontColor,
      bgColor: f.bgColor,
      testShadows: f.testShadows || ViewText.DefaultViewTextFrame.testShadows,
      border: f.border || ViewText.DefaultViewTextFrame.border,
      blendMode: this.data.blendMode,
    }
    return r
  }
  trFunc(name) {
    return (...arg) => {
      return this[name](...arg)
    }
  }
  frameIn(nowf, nextf) {
    console.log('img frameIn')
    //this.textData = this.ftoTextdata(nowf)
    this.vm.$forceUpdate()
    setTimeout(() => {
      this.textData.transition = `all ${(nextf.time - nowf.time) / 1000}s linear`
      expotobj(this.textData, this.ftoTextdata(nextf))
      //this.textData.rect = nextf.rect
      //this.textData.opacity = nextf.opacity
      //this.textData.rotate = nextf.rotate
      //this.textData.fontColor = nextf.fontColor
      //this.textData.bgColor = nextf.bgColor
      //this.textData.testShadows = nextf.testShadows
      //this.textData.border = nextf.border
      this.vm.$forceUpdate()
    })
  }
  frameEnd(nowf, nextf) {
    this.textData.transition = null
    this.vm.$forceUpdate()
  }
  play() {
    this.endP = setTimeout(() => { this.end() }, this.data.duration - this.time)
    this.keyFrames.play(this.time, () => { console.log('kf ended') })
  }
  pause() {
    var t = this.keyFrames.stop()
    this.time = (this.vm.nowTime || 0) - this.data.timeline
    console.log(`keyFrames(${t})-nowTimeD(${this.time})=${t - this.time}`)
    this.vm.texts.splice(this.vm.texts.indexOf(this.textData), 1)
    this.textData = this.ftoTextdata(this.keyFrames.getFrame(this.time))
    this.vm.texts.push(this.textData)
    clearTimeout(this.endP)
    this.endP = null
  }
  continue() {
    var t = this.vm.nowTime
    this.time = t - this.data.timeline
    this.endP = setTimeout(() => { this.end() }, this.data.duration - this.time)
    this.keyFrames.play(this.time, () => { console.log('kf ended') })
  }
  stop() {
    this.keyFrames.stop()
    this.end()
  }
  end() {
    clearTimeout(this.endP)
    this.destroy()
  }
  destroy() {
    console.log('p destroy ViewText')
    if (this.keyFrames) {
      this.keyFrames.stop()
    }
    console.log('destroy ViewText')
    this.vm.texts.splice(this.vm.texts.indexOf(this.textData), 1)
    this.vm.removeRunnningAct(this)
  }
}
class SetView {
  constructor(vm, data, time) {
    this.vm = vm
    this.data = { ...data }
    this.keyFrames = new keyFrames(data.keyFrames, ViewText.DefaultViewTextFrame, data.duration, this.trFunc('frameIn'), this.trFunc('frameEnd'), null)
    this.time = time ? time - data.timeline : 0
    this.sencedata = this.vm.getSenceData(this.data.senceGuid)
    if (this.sencedata.linkItem.isPanoVideo) {
      if (this.vm.videoPlayer) {
        this.videoPlayer = this.vm.videoPlayer
        this.videoPlayer.stop()
        this.seekVideo(this.nowTime)
      } else {
        this.loading_videoEnable = this.vm.addLoading('videoEnable', (videoPlayer) => {
          if (!videoPlayer) {
            return
          }
          this.videoPlayer = videoPlayer
          console.log(this.videoPlayer)
          this.videoPlayer.stop()
          this.seekVideo(this.nowTime)
          this.loading_videoEnable = null
        })
      }
    }
    console.log('SetView c t', this.time)
    var nkf = this.keyFrames.getFrame(this.time)
    if (this.data.senceGuid && this.vm.nowSenceGuid() != this.data.senceGuid) {
      var d = this.getBlendDuration(this.data.blend || this.vm.tour.blend)
      this.vm.getFunc({
        target: 'FullPano',
        name: 'stopTweenView'
      })({ ...nkf.view })
      this.vm.setPano(this.data.senceGuid, { ...nkf.view, blend: this.data.blend })
      console.log('waiting panoLoad')
      if (this.data.waitLoad != false) {
        this.panoWaiting = this.vm.addLoading('panoLoad', () => {
          this.panoWaiting = this.vm.addLoading('self', () => {
            this.panoWaiting = null
          })
          setTimeout(this.panoWaiting, d)
        })
      }
    } else {
      this.vm.getFunc({
        target: 'FullPano',
        name: 'setView'
      })({ ...nkf.view })
    }

    return this
  }
  seekVideo(time) {
    if (this.videoPlayer && this.videoPlayer.play) {
      this.videoPlayer.seek(time / 1000)
      if (this.seekend) {
        this.seekend()
      }
      this.seekend = this.vm.addLoading('self', () => {
        this.seekend = null
        clearInterval(this.seeking)
        console.log('seeking end')
      })
      this.seeking = setInterval(() => {
        if (!this.videoPlayer.isseeking) {
          if (this.seekend) {
            this.seekend()
          }
        } else {
          console.log('no videoPlayer')
        }
      }, 100)
    }
  }
  getBlendDuration(blend) {
    if (blend) {
      var duration = Number(blend.match(/\((\S*),/)[1]) * 1000
      if (duration) {
        return duration
      }
    }
    return 0
  }
  static get DefaultViewTextFrame() {
    return {
      time: 0,
      view: {
        fov: 0,
        vLookAt: 0,
        hLookAt: 0,
        architectural: 0,
        pannini: 0,
        distortion: 0,
      },
      tweenType: null
    }
  }
  trFunc(name) {
    return (...arg) => {
      return this[name](...arg)
    }
  }
  frameIn(nowf, nextf) {
    console.log(nowf.tweenType, this.data.tweenType)
    this.vm.getFunc({
      target: 'FullPano',
      name: 'tweenView'
    })({ ...nextf.view, time: (nextf.time - nowf.time) / 1000, tweentype: nowf.tweenType || this.data.tweenType || 'EaseOutQuad' })
  }
  frameEnd(nowf, nextf) {
    this.vm.getFunc({
      target: 'FullPano',
      name: 'stopTweenView'
    })({ ...nextf.view })
  }
  play() {
    if (this.panoWaiting) {
      return
    }
    if (this.videoPlayer && this.videoPlayer.play) {
      this.videoPlayer.play()
    }
    console.log('setview play time', this.time)
    this.keyFrames.play(this.time, () => { console.log('kf ended') })
    this.endP = setTimeout(() => { this.end() }, this.data.duration - this.time)
  }
  pause() {
    if (this.videoPlayer && this.videoPlayer.play) {
      this.videoPlayer.pause()
    }

    var t = this.keyFrames.stop() || 0
    var kf = this.keyFrames.getFrame(t)
    this.vm.getFunc({
      target: 'FullPano',
      name: 'stopTweenView'
    })({ ...kf.view })
    this.time = (this.vm.nowTime || 0) - this.data.timeline
    clearTimeout(this.endP)
    this.endP = null
  }
  continue() {
    var t = this.vm.nowTime
    this.time = t - this.data.timeline
    console.log('setView continue time', this.time)
    this.play()
  }
  stop() {
    if (this.videoPlayer && this.videoPlayer.play) {
      this.videoPlayer.stop()
    }

    var t = this.keyFrames.stop() || 0
    var kf = this.keyFrames.getFrame(t)
    this.vm.getFunc({
      target: 'FullPano',
      name: 'stopTweenView'
    })({ ...kf.view })
    this.end()
  }
  end() {
    clearTimeout(this.endP)
    this.destroy()
  }
  destroy() {
    if (this.keyFrames) {
      this.keyFrames.stop()
    }
    this.vm.removeRunnningAct(this)
  }
}
class SetHotspot {
  constructor(vm, data, time) {
    this.vm = vm
    this.data = { ...data }
    this.guid = uuid.v4()
    this.keyFrames = new keyFrames(data.keyFrames, ViewText.DefaultViewTextFrame, data.duration, this.trFunc('frameIn'), this.trFunc('frameEnd'), null)
    this.time = time ? time - data.timeline : 0
    var nkf = this.keyFrames.getFrame(this.time)
    this.vm.getFunc({
      target: 'FullPano',
      name: 'setHotspot'
    })({ ...this.ftoHotspotData(nkf) })
    return this
  }
  static get DefaultViewTextFrame() {
    return {
      time: 0,
      hotspotView: {
        ath: 0,
        atv: 0,
        scale: 0.5,
        rotate: 0,
        rx: 0,
        ry: 0,
        rz: 0,
        chromakeyColor: '#000',
        threshold: 0,
        smoothing: 0,
      }
    }
  }
  trFunc(name) {
    return (...arg) => {
      return this[name](...arg)
    }
  }
  ftoHotspotData(f) {
    return {
      guid: this.guid,
      keep: true,
      ...this.data.hotspot,
      ...f.hotspotView
    }
  }
  frameIn(nowf, nextf) {
    this.vm.getFunc({
      target: 'FullPano',
      name: 'tweenHotspot'
    })({ ...this.ftoHotspotData(nextf), time: (nextf.time - nowf.time) / 1000, tweentype: nowf.tweenType || 'EaseOutQuad' })
  }
  frameEnd(nowf, nextf) {
    this.vm.getFunc({
      target: 'FullPano',
      name: 'stopTweenHotspot'
    })({ ...this.ftoHotspotData(nextf) })
  }
  play() {
    console.log('setview play time', this.time)
    this.keyFrames.play(this.time, () => { console.log('kf ended') })
    this.endP = setTimeout(() => { this.end() }, this.data.duration - this.time)
  }
  pause() {
    var t = this.keyFrames.stop() || 0
    var kf = this.keyFrames.getFrame(t)
    this.vm.getFunc({
      target: 'FullPano',
      name: 'stopTweenHotspot'
    })({ ...this.ftoHotspotData(kf) })

    this.time = (this.vm.nowTime || 0) - this.data.timeline
    clearTimeout(this.endP)
    this.endP = null
  }
  continue() {
    var t = this.vm.nowTime
    this.time = t - this.data.timeline
    console.log('setView continue time', this.time)
    this.play()
  }
  stop() {
    var t = this.keyFrames.stop() || 0
    var kf = this.keyFrames.getFrame(t)
    this.vm.getFunc({
      target: 'FullPano',
      name: 'removeHotspot'
    })({ ...this.ftoHotspotData(kf) })
    this.end()
  }
  end() {
    clearTimeout(this.endP)
    this.destroy()
  }
  destroy() {
    if (this.keyFrames) {
      this.keyFrames.stop()
    }
    this.vm.removeRunnningAct(this)
  }
}
class PausePoint {
  constructor(vm, data, time) {
    this.vm = vm
    this.data = { ...data }
    this.guid = uuid.v4()
    this.time = time
    this.played = false
    this.vm.pause()
    setTimeout(() => { this.vm.setTime(this.data.timeline) })
    switch (this.data.pauseMode) {
      case 'auto':
        this.vm.sendMassage(this.data.text, () => { this.vm.play() })
        break
      case 'manual':
      default:
        this.vm.sendMassage(this.data.text)
        this.vm.continueBtn = true;
        break
    }    this.end()
    return this
  }
  play() {
  }
  pause() {
  }
  continue() {
  }
  stop() {
    this.end()
  }
  end() {
    this.destroy()
  }
  destroy() {
    this.vm.removeRunnningAct(this)
  }
}
export {
  SetView,
  PlayAudio,
  SetHotspot,
  Subtitle,
  ViewImage,
  ViewText,
  PausePoint,
}
function expotobj(a, b) {
  for (var i in b) {
    a[i] = b[i]
  }
}
